import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate, NavLink }  from 'react-router-dom';
import LoggedLayout from 'layouts/LoggedLayout';
import { default as TankerTrucksService } from 'services/TankerTrucks';
import moment from 'moment';
import { assets } from 'helpers/generic';

const Wrapper = styled.div`
	position: absolute;
	top: 25px;
	width: 100%;
	height: 100%;
	background: var(--plenoil-red);
	color: white;
	overflow-y: auto;
`;

const CloseButton = styled.button`
	width: 30px;
	height: 30px;
	position: absolute;
	top: 10px;
	z-index: 2;
	left: 50%;
	margin-left: -15px;
	display: flex;
	justify-content: center;
	align-items: start;
	border: 4px solid var(--plenoil-red);
	color: black;
	background: lightgray;
	border-radius: 50%;
	font-size: 14px;

	i {

		&::before {
			-webkit-text-stroke: 1px;
		}
	}
`;

const TruckIcon = styled.img`
	width: 50px;
	display: block;
	margin: 0 auto;
	margin-top: 40px;
	margin-bottom: 10px;
`;

const Title = styled.div` 
	color: white;
	margin: 0 20px;
	text-align: center;
	margin-top: 10px;
	text-transform: uppercase;
	font-size: 20px;
	padding: 3px 10px;
`;

const Square = styled.div` 
	background: var(--plenoil-orange);
	padding: 30px 20px;
	border-radius: 35px;
	width: 90%;
	margin: 0 auto;
	position: relative;
	margin-bottom: 20px;

	.pcar {
		position: absolute;
		top: 0;
		left: 10%;
	}

	.loading {
		width: 50px;
		height: 50px;
		background: white;
		border-radius: 50%;
		z-index: 2;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 30px;
		color: var(--plenoil-black);
		margin: 0 auto;
		margin-top: 20px;
	}

	.loading-message {
		text-align: center;
		font-size: 20px;
		line-height: 20px;
		margin-top: 15px;
	}

	.error {
		width: 50px;
		height: 50px;
		background: var(--plenoil-red);
		border-radius: 50%;
		z-index: 2;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 40px;
		line-height: 40px;
		color: var(--plenoil-orange);
		margin: 0 auto;
		margin-top: 20px;
		margin-bottom: 15px;
	}

	.error-message {
		text-align: center;
		font-size: 20px;
		line-height: 20px;

		small {
			display: block;
			font-size: 14px;
			line-height: 16px;
			margin-top: 10px;
			margin-bottom: 20px;
		}

		a {
			display: block;
			margin: 0 auto;
			background: var(--plenoil-blue);
			border: 2px solid white;
			width: 230px;
			padding: 10px 20px;
			border-radius: 20px;
			color: white;
			font-size: 16px;
			cursor: pointer;
			text-decoration: none;
		}

		.oil {
			display: flex;
			justify-content: center;
			align-items: center;
			margin-bottom: 18px;
			font-size: 18px;

			.circle {
				width: 20px;
				height: 20px;
				border-radius: 50%;
				margin-right: 5px;

				&.sp95 {
					background: var(--plenoil-green);
				}

				&.goa {
					background: var(--plenoil-black);
				}
			}
		}
	}
`;

const Dark = styled.div` 
	color: white;
	background: black;
	width: 80%;
	text-align: center;
	margin: 0 auto;
`;

const Text = styled.div` 
	color: white;
	font-size: 20px;
	text-align: center;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	line-height: ${props => props.lineHeight ?? ''};

	small {
		font-size: 13px;
	}
`;

const Circle = styled.div` 
	width: 25px;
	height: 25px;
	border-radius: 50%;
	border: 2px solid white;
	display: inline-block;
	background: ${props => {
		switch (props.type) {
			case 'diesel': return 'var(--plenoil-black)';
			case 'sp95': return 'var(--plenoil-green)';
			case 'diesel-ad': return 'var(--plenoil-blue-2)';
			case 'sp95-ad': return 'var(--plenoil-orange-2)';
			default: return 'var(--plenoil-black)';
		}
	}};
	margin-right: 10px;
`;

const CheckButton = styled.div` 
	--size: 35px;

	display: inline-flex;
	flex-direction: row;
	align-items: center;
	border-radius: var(--size);
	border: 2px solid white;
	margin-top: 20px;
	cursor: pointer;
	box-shadow: 0px 0px 10px rgba(1,1,1,0.2);
	
	div {
		background: var(--plenoil-blue);
		width: var(--size);
		height: var(--size);
		border-radius: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 0 auto;
		padding: 6px;

		img {
			width: 100%;
		}
	}

	span {
		display: block;
		text-align: center;
		margin: 0 10px;
		text-transform: uppercase;
	}
`;

export default function TankerTrucks() {
	let navigate = useNavigate();

	let [currents, setCurrents] = useState([]);
	let [nexts, setNexts] = useState([]);

	useEffect(() => {
		const getData = async () => {
			let currents = await TankerTrucksService.getCurrentsList();
			let nexts = await TankerTrucksService.getNextsList();

			setCurrents(currents);
			setNexts(nexts);

			if ( currents.length ) {
				currents.forEach(el => {
					TankerTrucksService.updateField(el.id, 'seen_at', moment().format('YYYY-MM-DD HH:mm')); 
				});
			}
		};
		getData();

		let getDataInterval = setInterval(() => {
			getData();
		}, 5000);

		return function cleanup() {
			TankerTrucksService.cancel();
			clearInterval(getDataInterval);
		}
	}, []);

	return (
		<LoggedLayout hideFooter={true}>
			<CloseButton onClick={() => navigate('/')}>
				<i className="bi bi-chevron-down"></i>
			</CloseButton>

			<Wrapper>
				<TruckIcon src={assets('/img/cisternas-icono.svg')} alt="Comprobación" />
				<Title className="mb-3">COMPROBACIÓN DE CISTERNA</Title>

				{currents?.map((tankerTruck, idx) => {
					let content = null;

					// Processing
					if ( tankerTruck.photos?.filter(photo => photo.ocr_at === null).length > 0 ) {
						content = (
							<>
								<div className="loading">
									<i className="bi bi-hourglass-split"></i>
								</div>
								<div className="loading-message">
									LAS IMÁGENES SE ESTÁN PROCESANDO
								</div>
							</>
						);
					}

					// With errors
					if ( 
						tankerTruck.photos?.filter(photo => photo.ocr_at === null).length === 0 // There aren't not processed photos
						// &&
						// tankerTruck.photos?.filter(photo => photo.ocr_result === 0).length > 0 // There are photos with processing errors
					) {
						content = (
							<>
								<div className="error">
									<i className="bi bi-x"></i>
								</div>
								<div className="error-message">
									<div>
										{ tankerTruck.diesel_liters > 0 &&
											<div className="oil"> 
												<div className="circle goa"></div>
												<span>GOA</span>
											</div>
										}
										{ tankerTruck.sp95_liters > 0 &&
											<div className="oil">
												<div className="circle sp95"></div>
												<span>SP95</span>
											</div>
										}
									</div>

									LAS IMÁGENES NO HAN SIDO<br />
									PROCESADAS

									<small>Vuelve a tomar la foto y envíala nuevamente</small>

									<NavLink to={'/tankertrucks/check/photos/' + tankerTruck.id + '?force_upload=true'}>REENVIAR ALBARANES</NavLink>
								</div>
							</>
						);
					}
					
					// Pending
					if ( tankerTruck.photos?.length === 0 ) {
						content = (
							<>
								<Text>{ moment(tankerTruck.date).format('DD-MM-YYYY') }</Text>

								<Dark className="mb-2 mt-2">HORA</Dark>
								<Text>{ moment('2022-09-16 ' + tankerTruck.hour_expected_init).format('HH:mm')} - {moment('2022-09-16 ' + tankerTruck.hour_expected_end).format('HH:mm') }</Text>

								<Dark className="mb-2 mt-2">COMBUSTIBLE</Dark>
								{ tankerTruck.diesel_liters > 0 &&
									<Text className="text-end" lineHeight={'25px'}><span className="me-2" style={{width: '80px'}}>DIESEL</span> <Circle type="diesel"></Circle> <span style={{width: '60px', textAlign: 'left'}}>{ tankerTruck.diesel_liters }</span></Text>
								}
								{ tankerTruck.sp95_liters > 0 &&
									<Text className="text-end" lineHeight={'25px'}><span className="me-2" style={{width: '80px'}}>SP95</span> <Circle type="sp95"></Circle> <span style={{width: '60px', textAlign: 'left'}}>{ tankerTruck.sp95_liters }</span></Text>
								}

								{ tankerTruck.diesel_litrosaditivados > 0 &&
									<Text className="text-end mt-2 mb-0" lineHeight={'25px'}>
										<div className="d-flex flex-column me-2" style={{width: '80px'}}>
											<span>DIESEL</span>
											<small className="badge text-white p-0 text-end">ADITIVADO</small> 
										</div>
										<Circle type="diesel-ad"></Circle>
										<span style={{width: '60px', textAlign: 'left'}}>{ tankerTruck.diesel_litrosaditivados }</span>
									</Text>
								}

								{ tankerTruck.sp95_litrosaditivados > 0 &&
									<Text className="text-end" lineHeight={'25px'}>
										<div className="d-flex flex-column me-2" style={{width: '80px'}}>
											<span>SP95</span>
											<small className="badge text-white p-0 text-end">ADITIVADO</small> 
										</div>
										<Circle type="sp95-ad"></Circle>
										<span style={{width: '60px', textAlign: 'left'}}>{ tankerTruck.sp95_litrosaditivados }</span>
									</Text>
								}

								{ tankerTruck.parent &&
									<Text className="mt-3">Desvío desde { tankerTruck?.parent?.station?.name }</Text>
								}

								<div className="text-center">
									<CheckButton onClick={() => navigate('/tankertrucks/check/' + tankerTruck.id)}>
										<div><img src={assets('/img/cisternas-icono.svg')} alt="Comprobación" /></div>
										<span>REALIZAR COMPROBACIÓN</span>
									</CheckButton>
								</div>
							</>
						);
					}
					
					return (
						<React.Fragment key={idx}>
							<Square>
								<Dark className="pcar">{moment(tankerTruck.date).format('DD-MM-YYYY')}</Dark>

								{content}
							</Square>
						</React.Fragment>
					);
				})}
				
				{nexts?.map((tankerTruckNext, idx) => {
					return (
						<React.Fragment key={idx}>
							<Square>
								<Dark className="pcar">{tankerTruckNext.order}</Dark>
								<Title className="mt-0">Próxima cisterna</Title>

								<Text>{ moment(tankerTruckNext.date).format('DD-MM-YYYY') }</Text>

								<Dark className="mb-2 mt-2">HORA</Dark>
								<Text>{ moment('2022-09-16 ' + tankerTruckNext.hour_expected_init).format('HH:mm')} - {moment('2022-09-16 ' + tankerTruckNext.hour_expected_end).format('HH:mm') }</Text>

								<Dark className="mb-2 mt-2">COMBUSTIBLE</Dark>
								{ tankerTruckNext.diesel_liters > 0 &&
									<Text lineHeight={'25px'}><span style={{width: '80px'}}>DIESEL</span> <Circle type="diesel"></Circle> <span style={{width: '60px', textAlign: 'left'}}>{ tankerTruckNext.diesel_liters }</span></Text>
								}
								{ tankerTruckNext.sp95_liters > 0 &&
									<Text lineHeight={'25px'}><span style={{width: '80px'}}>SP95</span> <Circle type="sp95"></Circle> <span style={{width: '60px', textAlign: 'left'}}>{ tankerTruckNext.sp95_liters }</span></Text>
								}

								{ tankerTruckNext.parent &&
									<Text className="mt-3">Desvío desde { tankerTruckNext?.parent?.station?.name }</Text>
								}

								{/* <Dark className="pcar">{tankerTruckNext.order}</Dark>
								<Title>Próxima cisterna</Title>
								
								<div className="d-flex w-100 justify-content-between">
									<div>{ moment(tankerTruckNext.date).format('DD-MM-YYYY') }</div>
									<div>{ moment('2022-09-16 ' + tankerTruckNext.hour_expected_init).format('HH:mm')} - {moment('2022-09-16 ' + tankerTruckNext.hour_expected_end).format('HH:mm') }</div>
								</div> */}
							</Square>
						</React.Fragment>
					);
				})}

				{ (!currents?.length && !nexts?.length) &&
					<div className="text-center mt-5">
						No hay próxima cisterna
					</div>
				}
			</Wrapper>
		</LoggedLayout>
	);
}


